export enum InterventionActionType
{
  Spread = "Spread",
  Cancel = "Cancel"
}

export enum InterventionStatus
{
  Open = "Open",
  Approved = "Approved"
}
